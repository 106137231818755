<template>
  <div class="com-box vn-flex vn-space-between" style="width: 100%;">
    <div class="content-box vn-flex vn-flex-column vn-align-center">
      <iframe
        width="100%"
        height="600px;"
        src="http://pps.cnwaterservice.com.cn/platform/m/article/list?uid=389&msgtype=TSGG&nonce=728781fc-a2d6-40a7-b46e-129f25228ba8&signature=54f742f23412d6e2151d4c9199d34d96e429faf3#/"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
